import {
  Box,
  Container,
  DividedList,
  Heading,
  Intro,
  Link,
  Row,
  Section,
  TimeLine,
  YouTubeVideo
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import ContentLayoutDiversity from "../../../components/social-policy/ContentLayoutDiversity"
import React from "react"
import { graphql } from "gatsby"

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    ogImage: IGatsbyImageData
    prideLogoImage: IGatsbyImageData
    aweiImage: IGatsbyImageData
  }
}

const LgbtiSupportAtLwbPage = ({
  location,
  data: { site, ogImage, prideLogoImage, aweiImage }
}: Props) => (
  <ContentLayoutDiversity
    location={location}
    site={site}
    breadcrumbs={[
      {
        title: "Social policy",
        text: "Social policy",
        to: "/social-policy"
      },
      {
        title: "Diversity and inclusion",
        text: "Diversity and inclusion",
        to: "/social-policy/diversity-and-inclusion"
      }
    ]}
    contentProps={{
      metaTitle: "LGBTQIA+",
      metaDescription:
        "We are committed to inclusion of all members of the LGBTQIA+ community, and we have continued to proactively recruit same-sex foster carers for many years.",
      ogImage,
      title: "LGBTQIA+"
    }}
  >
    <div>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Intro text="At Life Without Barriers, our LGBTQIA+ inclusion journey came from humble beginnings as one of the first organisations to offer same-gender couples the opportunity to become foster carers. We recognise and respect all the ways relationships and families are found and formed and continue to actively seek people to join our growing network of LGBTQIA+ carers." />
          </Box>
        </Row>
        <Row className="flex-wrap">
          <Box className="w-100 w-two-thirds-l layout-gap">
            <Heading size={2} topMargin="standard">
              Creating an LGBTQIA+ inclusive workplace
            </Heading>
            <p>
              Life Without Barriers welcomes all those of diverse sexual
              orientation, gender identity, and sex characteristics to join our
              organisation and supports all LGBTQIA+ employees to bring their
              whole selves to work.
            </p>
            <p>
              The number of employees sharing with us that they identify as
              LGBTQIA+ increased from 6% in 2018 (Employee Engagement Survey),
              to 15.5% in 2021 (Diversity & Inclusion Survey).
            </p>
          </Box>
          <Box className="w-100 w-25-l mt5 mt4-l">
            <GatsbyImage
              image={getGatsbyImage(prideLogoImage)}
              alt="Life Without Barriers - Pride Without Barriers"
            />
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-theme-xxx-light">
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-two-thirds-l layout-gap">
              <Heading size={2}>
                Australian Workplace Equality Index (AWEI)
              </Heading>
              <p>
                Life Without Barriers has participated in the{" "}
                <Link to="https://www.pid-awei.com.au/">
                  Australian Workplace Equality Index
                </Link>{" "}
                (AWEI) for the past four years. Our score has increased each
                year from 18/200 in 2018, 54/200 in 2019, 74/200 in 2020, to
                102/200 in 2021. This year we achieved{" "}
                <Link
                  rooted={true}
                  to="/news/life-without-barriers-achieves-awei-bronze-employer-recognition/"
                >
                  Bronze Employer Recognition
                </Link>{" "}
                for the first time.
              </p>
              <blockquote className="color-lwb-theme-medium ts-display-5 tl ml0">
                “The achievement of Bronze Employer Recognition has been four
                years in the making. It&apos;s inspiring to be one of 186
                Australian organisations participating in the AWEI and working
                toward LGBTQIA+ workplace inclusion. It&apos;s a testament to
                the power of business in influencing not only organisational
                culture but broader community attitudes.”
              </blockquote>
              <div className="color-lwb-theme-medium fw7 ts-display-6">
                Ashleigh Sternes, Diversity and Inclusion Lead
              </div>
            </Box>
            <Box className="w-100 w-25-l mt5 mt4-l">
              <GatsbyImage
                image={getGatsbyImage(aweiImage)}
                alt="Australian Workplace Equality Index"
              />
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box className="layout-readable">
            <Heading size={2}>Pride Without Barriers</Heading>
            <p>
              In February 2021 we launched our second employee network Pride
              Without Barriers. Pride Without Barriers serves as a network and
              resource for LGBTQIA+ employees and their allies.
            </p>
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-grey-xxx-light">
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>Meet our LGBTQIA+ foster carers</Heading>
              <p>
                Jo and Kaz are both short- and long-term foster carers with Life
                Without Barriers. Read more stories from our LGBTQIA+{" "}
                <Link to="https://www.lwb.org.au/foster-care/stories/">
                  foster carers
                </Link>
                .
              </p>
            </Box>
            <Box className="w-100">
              <YouTubeVideo
                title="The Home that Love Built - Meet Kaz and Jo."
                videoId="D0m-upqCHFc"
                className="mt4"
              />
            </Box>
          </Row>
        </Container>
      </Section>
      <Section background="bg-hex-image">
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100">
              <Heading size={2} className="color-lwb-theme-medium">
                Sydney Gay & Lesbian Mardi Gras Parade
              </Heading>
            </Box>
            <Box className="w-100 w-50-l">
              <YouTubeVideo
                className="mt4"
                title="Life Without Barriers Sydney Gay and Lesbian Mardi Gras Parade 2019"
                videoId="PWlYGn6RXgk"
              />
              <div className="color-lwb-theme-medium ts-display-4 fw8 mt2 mt3-l">
                2019
              </div>
            </Box>
            <Box className="w-100 w-50-l">
              <YouTubeVideo
                className="mt4"
                title="Our First Mardi Gras March - short version"
                videoId="mCQLztUL-H4"
              />
              <div className="color-lwb-theme-medium ts-display-4 fw8 mt2 mt3-l">
                2017
              </div>
            </Box>
          </Row>
        </Container>
      </Section>
      <Container>
        <Row>
          <Box>
            <Heading size={2}>Our LGBTQIA+ inclusion journey</Heading>
          </Box>
        </Row>
        <Row topMargin="standard">
          <Box>
            <TimeLine
              events={[
                {
                  label: "2021",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          <Link
                            rooted={true}
                            to="/news/life-without-barriers-achieves-awei-bronze-employer-recognition/"
                          >
                            Celebrated Bronze Employer Recognition
                          </Link>{" "}
                          in the AWEI
                        </li>
                        <li className="pb3 ts-display-5">
                          Launched our second employee network, Pride Without
                          Barriers.
                        </li>
                        <li className="pb3 ts-display-5">
                          Updated the Life Without Barriers email signatures to
                          give employees the option to share their personal
                          pronouns
                        </li>
                        <li className="pb3 ts-display-5">
                          Celebrated International Day Against LGBTQIA+
                          Discrimination (IDAHOBIT) by releasing a collection of
                          pride-themed virtual backgrounds in the colours of the
                          pride, trans, bisexual, and intersex pride flags.
                        </li>
                        <li className="pb3 ts-display-5">
                          Participated in{" "}
                          <Link
                            rooted={true}
                            to="/news/pride-without-barriers-in-brisbane/"
                          >
                            Brisbane Pride
                          </Link>
                        </li>
                        <li className="pb3 ts-display-5">
                          Shared our support for{" "}
                          <Link to="https://fb.watch/9hrFCwNHe9/">
                            Pride Adelaide
                          </Link>
                        </li>
                      </>
                    </DividedList>
                  )
                },
                {
                  label: "2020",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          Participated in{" "}
                          <Link
                            rooted={true}
                            to="/news/shining-bright-at-mardi-gras-2020-with-life-without-barriers/"
                          >
                            Sydney Gay and Lesbian Mardi Gras
                          </Link>{" "}
                          for the third time
                        </li>
                      </>
                    </DividedList>
                  )
                },
                {
                  label: "2019",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          Participated in{" "}
                          <Link to="https://www.youtube.com/watch?v=PWlYGn6RXgk">
                            Sydney Gay and Lesbian Mardi Gras
                          </Link>{" "}
                          for the second time
                        </li>
                        <li className="pb3 ts-display-5">
                          Shortlisted for the Mardi Gras Awards Most Fabulous
                          Parade Entry
                        </li>
                        <li className="pb3 ts-display-5">
                          Introduced Gender Affirmation Leave to support trans
                          and gender diverse employees affirming their gender
                        </li>
                        <li className="pb3 ts-display-5">
                          Became members of{" "}
                          <Link to="https://www.welcomehere.org.au/">
                            ACON’s Welcome Here Project
                          </Link>
                        </li>
                        <li className="pb3 ts-display-5">
                          Received our second nomination for the LGBTI Awards
                        </li>
                      </>
                    </DividedList>
                  )
                },
                {
                  label: "2018",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          Participated in our first{" "}
                          <Link to="https://www.pid-awei.com.au/">AWEI</Link>
                        </li>
                        <li className="pb3 ts-display-5">
                          Nominated for our first LGBTI Award
                        </li>
                        <li className="pb3 ts-display-5">
                          Reviewed and updated our HR policies and procedures
                          for inclusive language
                        </li>
                      </>
                    </DividedList>
                  )
                },
                {
                  label: "2017",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          Publicly supported the Marriage Equality campaign
                        </li>
                        <li className="pb3 ts-display-5">
                          Became members of{" "}
                          <Link to="https://www.prideinclusionprograms.com.au/">
                            Pride in Diversity
                          </Link>
                        </li>
                        <li className="pb3 ts-display-5">
                          Participated in our first{" "}
                          <Link to="https://www.youtube.com/watch?v=mCQLztUL-H4">
                            Sydney Gay and Lesbian Mardi Gras
                          </Link>
                        </li>
                      </>
                    </DividedList>
                  )
                },
                {
                  label: "2001",
                  node: (
                    <DividedList>
                      <>
                        <li className="pb3 ts-display-5">
                          Began recruiting LGBTQIA+ foster carers
                        </li>
                      </>
                    </DividedList>
                  )
                }
              ]}
            />
          </Box>
        </Row>
      </Container>
    </div>
  </ContentLayoutDiversity>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { regex: "/banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1800)
      }
    }
    prideLogoImage: file(
      relativePath: { regex: "/lwb-lgbtqi-pride-logo.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
    aweiImage: file(
      relativePath: { regex: "/2023-26-awei-adg-bronze-employer.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
export default LgbtiSupportAtLwbPage
